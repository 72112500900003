import { TextField, styled } from '@mui/material';

export const MainInput = styled(TextField)({
  width: '100%',
  paddingTop: '4px',
  color: 'var(--grey4) !important',
  '& label': {
    fontSize: '1.3rem',
    color: 'var(--grey4)',
  },
  '& label.Mui-focused': {
    color: 'var(--grey4)',
  },
  '& .MuiInput-input': {
    color: 'var(--grey4)',
    fontSize: '1.1rem',
  },
  '& input': {
    color: 'var(--grey4)',
    fontSize: '1.1rem',
  },
  '&:hover': {
    '& .MuiInput-root.Mui-focused:after': {
      borderBottom: '2px solid var(--grey9)',
    },
  },
  '& .MuiInput-root.Mui-focused:after': {
    borderBottom: '2px solid var(--white)',
  },
});
