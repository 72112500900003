export function BookIcon(props: { color: string }) {
  const { color } = props;

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 94C73.4051 94 94 73.4051 94 48C94 22.5949 73.4051 2 48 2C22.5949 2 2 22.5949 2 48C2 73.4051 22.5949 94 48 94Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.4444 35.7778C48.4444 33.1845 47.4143 30.6975 45.5806 28.8638C43.7469 27.0302 41.2599 26 38.6667 26H24V62.6667H41.1111C43.056 62.6667 44.9213 63.4393 46.2966 64.8145C47.6718 66.1898 48.4444 68.0551 48.4444 70M48.4444 35.7778V70M48.4444 35.7778C48.4444 33.1845 49.4747 30.6975 51.3084 28.8638C53.1421 27.0302 55.6291 26 58.2223 26H72.889V62.6667H55.7779C53.833 62.6667 51.9677 63.4393 50.5924 64.8145C49.2172 66.1898 48.4444 68.0551 48.4444 70"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
