export function Cassandra() {
  return (
    <svg
      width="69"
      height="72"
      viewBox="0 0 69 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.62819 25.5789C0.569236 28.8777 0 32.3763 0 36C0 39.2787 0.466026 42.4551 1.33918 45.4737H51.1599C56.6537 45.4737 61.1073 41.0201 61.1073 35.5263C61.1073 30.0325 56.6537 25.5789 51.1599 25.5789H1.62819Z"
        fill="white"
      />
      <path
        d="M3.54817 51.1579C9.63152 63.4662 22.8942 72 38.276 72C50.8587 72 62.0233 66.2895 69 57.4737C67.8748 55.8015 65.8574 54.4777 63.6752 53.4753C58.8542 51.2606 53.4054 51.1579 48.1001 51.1579H3.54817Z"
        fill="white"
      />
      <path
        d="M38.276 0C23.1587 0 10.0883 8.24281 3.86833 20.2105H56.071C59.0944 19.7895 65.9131 18.0632 69 14.5263C62.0233 5.71049 50.8587 0 38.276 0Z"
        fill="white"
      />
    </svg>
  );
}
