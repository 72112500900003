export function Confluence() {
  return (
    <svg
      width="66"
      height="72"
      viewBox="0 0 66 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9405 12.6736C31.373 12.6736 33.3449 14.6455 33.3449 17.078C33.3449 17.5474 33.2715 17.9996 33.1355 18.4238L39.479 24.9081C39.9335 24.7496 40.4218 24.6634 40.9303 24.6634C43.3628 24.6634 45.3347 26.6353 45.3347 29.0678C45.3347 31.5003 43.3628 33.4723 40.9303 33.4723C38.4978 33.4723 36.5259 31.5003 36.5259 29.0678C36.5259 28.4876 36.6381 27.9337 36.8419 27.4264L30.898 21.4824V37.1111C32.3482 37.8318 33.3449 39.3283 33.3449 41.0577C33.3449 43.4902 31.373 45.4621 28.9405 45.4621C26.508 45.4621 24.536 43.4902 24.536 41.0577C24.536 39.2327 25.646 37.667 27.2276 36.9987V21.137C25.646 20.4687 24.536 18.903 24.536 17.078C24.536 16.4705 24.659 15.8917 24.8815 15.3652L18.4188 8.77951L1.16519 25.8047C-0.383318 27.3327 -0.389132 29.8302 1.15224 31.3654L26.1665 56.2796C27.6985 57.8055 30.1774 57.8005 31.7033 56.2685L56.397 31.4748C57.9228 29.9428 57.9178 27.4639 56.3858 25.9381L31.4888 1.14113C29.9494 -0.392138 27.4559 -0.378549 25.9332 1.1714L20.988 6.20526L27.4892 12.9183C27.9436 12.7598 28.432 12.6736 28.9405 12.6736Z"
        fill="white"
      />
      <path
        d="M54.8776 50.3559C54.8776 51.5722 53.8917 52.5582 52.6754 52.5582C51.4592 52.5582 50.4732 51.5722 50.4732 50.3559C50.4732 49.1397 51.4592 48.1537 52.6754 48.1537C53.8917 48.1537 54.8776 49.1397 54.8776 50.3559Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.909 63.3245C45.0172 63.3245 47.5369 61.2978 47.5369 58.7977C47.5369 57.8963 47.2093 57.0564 46.6447 56.3508C45.6434 55.0996 43.8965 54.271 41.909 54.271C38.8009 54.271 36.2812 56.2977 36.2812 58.7977C36.2812 60.2159 37.092 61.4818 38.361 62.3118C38.9953 62.7266 39.744 63.0326 40.5633 63.1943C40.9945 63.2794 41.4452 63.3245 41.909 63.3245ZM42.1537 60.8776C43.37 60.8776 44.3559 59.8917 44.3559 58.6754C44.3559 57.4592 43.37 56.4732 42.1537 56.4732C40.9375 56.4732 39.9515 57.4592 39.9515 58.6754C39.9515 59.8917 40.9375 60.8776 42.1537 60.8776Z"
        fill="white"
      />
      <path
        d="M41.909 54.271C43.8965 54.271 45.6434 55.0996 46.6447 56.3508H48.5157V54.271H41.909Z"
        fill="white"
      />
      <path
        d="M54.8776 64.4256V54.271H49.9838V56.3508H50.2285C50.7691 56.3508 51.2073 56.7891 51.2073 57.3296V64.4256H49.6168V66.5055H56.4681V64.4256H54.8776Z"
        fill="white"
      />
      <path
        d="M65.0323 56.3508V54.271H61.6066V50.3559H59.282V51.09C59.282 52.8468 57.8579 54.271 56.1011 54.271V56.3508H57.9363V63.5692C57.9363 65.1909 59.2509 66.5055 60.8725 66.5055H61.4843H62.9524C63.7354 66.5055 65.073 65.853 65.644 65.5267L64.7876 63.2022C64.2982 63.4958 63.401 63.8955 62.9524 63.9362C62.1416 63.9362 61.4843 63.2789 61.4843 62.4681V56.3508H65.0323Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.361 62.3118C37.7085 62.8941 36.4035 64.2543 36.4035 65.0373C36.4035 66.0161 36.7706 66.5055 37.2599 66.7502C36.8929 67.1172 36.1588 68.096 36.1588 69.0747C36.1588 70.2982 36.7706 71.644 39.2175 71.8887C41.6644 72.1334 45.7017 72.011 46.5582 71.1546C47.4146 70.2982 48.3933 69.5641 48.3933 67.9736C48.3933 66.3831 46.4358 64.4256 44.2336 64.4256C42.0314 64.4256 40.0739 64.3033 39.9515 64.0586C39.8537 63.8628 40.3186 63.4008 40.5633 63.1943C39.744 63.0326 38.9953 62.7266 38.361 62.3118ZM43.8666 69.4418C45.3347 69.3194 45.09 67.8513 44.2336 67.6066C43.5887 67.4223 41.0869 67.4183 39.7068 67.37C39.4621 67.9383 38.3655 69.0512 39.7068 69.3194C40.3186 69.4418 42.3984 69.5641 43.8666 69.4418Z"
        fill="white"
      />
    </svg>
  );
}
