export function Word() {
  return (
    <svg
      width="70"
      height="52"
      viewBox="0 0 70 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5122 35.0133C67.0953 35.0133 70 32.0644 70 28.4267C70 24.789 67.0953 21.84 63.5122 21.84C59.9291 21.84 57.0244 24.789 57.0244 28.4267C57.0244 29.1151 57.1284 29.7789 57.3214 30.4026L36.7672 41.69C35.5979 39.9608 33.6367 38.8267 31.4146 38.8267C28.9434 38.8267 26.7949 40.2294 25.6994 42.2933L8.47704 33.2535C5.33285 31.6031 3.53559 28.1376 3.97461 24.5719C4.19948 22.7455 6.15063 21.7081 7.75743 22.5606L29.6296 34.1647C30.4265 34.5875 31.3772 34.5851 32.172 34.1583L66.2971 15.8349C67.541 15.167 67.4996 13.3438 66.2267 12.7347L42.0199 1.15313C40.4988 0.425344 38.7322 0.449283 37.2308 1.21803L3.75412 18.3579C1.45203 19.5366 0 21.931 0 24.5486V25.477C0 30.4709 2.78147 35.0354 7.18309 37.2648L24.9825 46.28C25.4004 49.5081 28.1209 52 31.4146 52C34.9407 52 37.8097 49.1443 37.9002 45.5867L59.5296 33.6267C60.6289 34.4957 62.011 35.0133 63.5122 35.0133ZM29.4683 45.4133C29.4683 46.5046 30.3397 47.3893 31.4146 47.3893C32.4896 47.3893 33.361 46.5046 33.361 45.4133C33.361 44.322 32.4896 43.4374 31.4146 43.4374C30.3397 43.4374 29.4683 44.322 29.4683 45.4133ZM63.5122 30.4026C62.4373 30.4026 61.5659 29.518 61.5659 28.4267C61.5659 27.3354 62.4373 26.4507 63.5122 26.4507C64.5871 26.4507 65.4585 27.3354 65.4585 28.4267C65.4585 29.518 64.5871 30.4026 63.5122 30.4026Z"
        fill="white"
      />
    </svg>
  );
}
