import { Alert, styled } from '@mui/material';

export const MessageAlert = styled(Alert)({
  width: '100%',
  padding: '0 16px',
  color: 'var(--white)',
  // height: 40,
  // root: {
  //   margin: '0 16px',
  // }
});
