import { useState, ChangeEvent } from 'react';

export function useInput<T extends HTMLInputElement | HTMLTextAreaElement>(
  initialValue: string | number,
  mask?: RegExp,
) {
  const [value, setValue] = useState(String(initialValue));
  const [isError, setIsError] = useState(false);

  function setIsErrorInTrue() {
    setIsError(true);
  }

  function onChange(event: ChangeEvent<T>) {
    setIsError(false);
    const getValue = event.target.value;
    if (!mask) {
      setValue(getValue);
    }

    if (mask?.test(getValue) || getValue === '') {
      setValue(getValue);
    }
  }

  function setValueInField(value: number | string) {
    setIsError(false);

    if (mask === undefined) {
      setValue(String(value || ''));
    } else if (mask.test(String(value)) || value === '') {
      setValue(String(value));
    }
  }

  return {
    value,
    onChange,
    setValueInField,
    isError,
    setIsErrorInTrue,
  };
}
