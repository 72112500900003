import { useState, useEffect } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

export function useScreenSize(): WindowSize {
  const [isClient, setIsClient] = useState(false);
  const [screenSize, setScreenSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;

    function handleResize() {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isClient]);

  return screenSize;
}
