export function Telegram() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.841 45.7126C28.7597 46.2406 28.0182 46.2902 27.8672 45.7778L26.1062 39.7994C26.0433 39.5858 26.1292 39.3563 26.3169 39.2366L39.3576 30.9176C39.871 30.5902 40.4129 31.2908 39.967 31.7054L29.6273 41.3174C29.5448 41.3941 29.4907 41.4963 29.4735 41.6075L28.841 45.7126Z"
        fill="white"
      />
      <path
        d="M31.4299 49.1104C31.0916 49.406 30.5653 49.1401 30.6027 48.6923L30.9269 44.8078C30.9592 44.4214 31.3998 44.2171 31.7155 44.4423L33.9849 46.0608C34.2478 46.2483 34.2667 46.632 34.0236 46.8444L31.4299 49.1104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 35.9441C72 55.7962 55.8823 71.8896 36 71.8896C16.1177 71.8896 0 55.7962 0 35.9441C0 16.0919 16.1177 -0.00146484 36 -0.00146484C55.8823 -0.00146484 72 16.0919 72 35.9441ZM28 50.9214L24.1559 40.366C24.0569 40.0941 23.8449 39.8785 23.5748 39.7747L15.9782 36.8574C14.2531 36.1949 14.2723 33.7475 16.0076 33.1122L52.4693 19.7632C53.9737 19.2124 55.4827 20.5655 55.0986 22.1208L47.7059 52.0569C47.3673 53.428 45.7527 54.0258 44.6029 53.2057L37.1148 47.8651C36.751 47.6057 36.2592 47.6191 35.91 47.898L31.5 51.4206L30.9159 51.7706C29.882 52.39 28.5396 51.999 28 50.9214Z"
        fill="white"
      />
    </svg>
  );
}
