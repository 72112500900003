export function Figma() {
  return (
    <svg
      width="50"
      height="72"
      viewBox="0 0 50 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.8197C0 5.73959 5.70837 0 12.75 0H36.25C43.2916 0 49 5.73959 49 12.8197C49 17.6312 46.3638 21.8236 42.4644 24.0163C46.9288 26.2305 50 30.8531 50 36.1969C50 43.6935 43.9558 49.7707 36.5 49.7707C32.2591 49.7707 28.4749 47.8046 26 44.7293V60.8309C26 66.9393 21.0751 71.8911 15 71.8911H13C5.8203 71.8911 0 66.0389 0 58.82V58.5686C0 53.5102 2.80383 49.1102 6.9341 46.8542C2.81691 44.7285 0 40.4164 0 35.4428C0 30.5458 2.73075 26.2902 6.74468 24.1313C2.73075 21.9724 0 17.7167 0 12.8197ZM12.75 22.6231C7.36522 22.6231 3 18.234 3 12.8197C3 7.40551 7.36522 3.01641 12.75 3.01641H23V22.6231H12.75ZM23 25.6395H12.75C7.36522 25.6395 3 30.0286 3 35.4428C3 40.857 7.36522 45.2461 12.75 45.2461H23V25.6395ZM26 27.6645V25.6395H28.014C27.2741 26.2415 26.5987 26.9205 26 27.6645ZM23 48.2625H13.25C7.58908 48.2625 3 52.8767 3 58.5686V58.82C3 64.373 7.47715 68.8747 13 68.8747H15C19.4183 68.8747 23 65.2734 23 60.8309V48.2625ZM26 22.6231V3.01641H36.25C41.6348 3.01641 46 7.40551 46 12.8197C46 18.234 41.6348 22.6231 36.25 22.6231H26ZM26 36.1969C26 42.0276 30.701 46.7543 36.5 46.7543C42.299 46.7543 47 42.0276 47 36.1969C47 30.3662 42.299 25.6395 36.5 25.6395C30.701 25.6395 26 30.3662 26 36.1969Z"
        fill="white"
      />
    </svg>
  );
}
