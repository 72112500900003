import { InfoItemType } from '@/shared/config/landing';
import styled from './InteractiveInfo.module.scss';
import { InfoItem } from './components/InfoItem/InfoItem';
import { Label } from '@/shared/ui/Label';

interface InteractiveInfoProps {
  title?: string;
  subTitle?: string;
  infoItems: InfoItemType[];
  id: string;
}

export const InteractiveInfo = ({
  subTitle,
  title,
  infoItems,
  id,
}: InteractiveInfoProps) => {
  return (
    <section className={styled.interactiveInfo} id={id}>
      {title && <Label text={title} />}

      {subTitle && <p className={styled.subTitle}>{subTitle}</p>}

      <div className={styled.infoItemContainer}>
        {infoItems.map((item) => (
          <InfoItem key={item.title} item={item} />
        ))}
      </div>
    </section>
  );
};
