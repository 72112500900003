import { Label } from '@/shared/ui/Label';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import styled from './FunctionalityOfSystem.module.scss';

export const FunctionalityOfSystem = () => {
  const { currentLocal } = useCurrentLocale();
  return (
    <section className={styled.FunctionalityOfSystem} id="functional">
      <Label text={currentLocal?.landing.functionBlock.functionTitle} />

      <div className={styled.listContainer}>
        <div className={styled.list}>
          <p className={styled.title}>
            {currentLocal?.landing.functionBlock.functionSubTitle}
          </p>

          <ul className={styled.ul}>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.firstFuncText}
            </li>
            <li className={styled.li2}>
              {currentLocal?.landing.functionBlock.secondFuncText}
            </li>
            <li className={styled.li2}>
              {currentLocal?.landing.functionBlock.thirdFuncText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.fourthFuncText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.fivesFuncText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.sixFuncText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.sevenFuncText}
            </li>
          </ul>
        </div>

        <div className={styled.list}>
          <p className={styled.title}>
            {currentLocal?.landing.functionBlock.additionalyTitle}
          </p>

          <ul className={styled.ul}>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.fisrtAdditionalyText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.secondAdditionalyText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.thirdAdditionalyText}
            </li>
            <li className={styled.li}>
              {currentLocal?.landing.functionBlock.fourthAdditionalyText}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
