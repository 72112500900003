export function ArrowDownIcon(props: { color: string }) {
  const { color } = props;

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="customGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="3%" style={{ stopColor: '#F8F4FF' }} />
          <stop offset="26%" style={{ stopColor: '#FF00F5' }} />
          <stop offset="41%" style={{ stopColor: '#A532FF' }} />
          <stop offset="62%" style={{ stopColor: '#0066FF' }} />
          <stop offset="91%" style={{ stopColor: '#F8F4FF' }} />
        </linearGradient>
      </defs>
      <path
        d="M47.7926 94C73.088 94 93.594 73.4051 93.594 48C93.594 22.5949 73.088 2 47.7926 2C22.4972 2 1.99121 22.5949 1.99121 48C1.99121 73.4051 22.4972 94 47.7926 94Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8701 48.0001L47.7924 66.0001M47.7924 66.0001L65.7147 48.0001M47.7924 66.0001L47.7924 30"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
