import { InfoItemType } from '@/shared/config/landing';
import styled from './InfoItem.module.scss';

interface InfoItemProps {
  item: InfoItemType;
}

export const InfoItem = (props: InfoItemProps) => {
  const { item } = props;

  return (
    <div className={styled.InfoItem}>
      <div className={styled.imageContainer}>
        {item.icon}
        <p className={styled.itemTitle}>{item.title}</p>
      </div>

      <p className={styled.itemDescription}>{item.description}</p>
    </div>
  );
};
