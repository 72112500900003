import styled from './label.module.scss';

type LabelProps = {
  text: string;
  id?: string;
};

export function Label({ text, id }: LabelProps) {
  return (
    <div id={id}>
      <span className={styled.root}>{text}</span>
    </div>
  );
}
