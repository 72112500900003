import { ContainerProps } from './types';
import styled from './Container.module.scss';

export function Container({ children }: ContainerProps) {
  return (
    <div className={styled.root}>
      <div className={styled.container}>{children}</div>
    </div>
  );
}
