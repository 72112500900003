export function NeuroIcon(props: { color: string }) {
  const { color } = props;

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.5964 52.0538L54.4303 62.446M54.4042 33.776L36.5964 44.1682M69.0003 29.8333C69.0003 34.1596 65.4932 37.6667 61.1669 37.6667C56.8407 37.6667 53.3336 34.1596 53.3336 29.8333C53.3336 25.5071 56.8407 22 61.1669 22C65.4932 22 69.0003 25.5071 69.0003 29.8333ZM37.6667 48.1112C37.6667 52.4374 34.1596 55.9445 29.8333 55.9445C25.5071 55.9445 22 52.4374 22 48.1112C22 43.7849 25.5071 40.2778 29.8333 40.2778C34.1596 40.2778 37.6667 43.7849 37.6667 48.1112ZM69.0003 66.3889C69.0003 70.7152 65.4932 74.2223 61.1669 74.2223C56.8407 74.2223 53.3336 70.7152 53.3336 66.3889C53.3336 62.0627 56.8407 58.5556 61.1669 58.5556C65.4932 58.5556 69.0003 62.0627 69.0003 66.3889Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 94C73.4051 94 94 73.4051 94 48C94 22.5949 73.4051 2 48 2C22.5949 2 2 22.5949 2 48C2 73.4051 22.5949 94 48 94Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
