import { SmartDocsLogo } from '@/public/assets/icons/SmartDocsLogo/SmartDocsLogo';
import { Container } from '@/shared/ui/Container';
import { Divider } from '@mui/material';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import { ContactWithUs, Documents } from './components';
import styled from './LandingFooter.module.scss';

export const LandingFooter = () => {
  const { currentLocal, isOnlyEn } = useCurrentLocale();
  return (
    <Container>
      <div className={styled.footerGradient} />

      <footer className={styled.Footer}>
        <div className={styled.footerHead}>
          <div className={styled.logoStyle}>
            <SmartDocsLogo />
          </div>
          <ContactWithUs />

          <div className={styled.footerInfoContainer}>
            <a
              href={`mailto:${currentLocal?.landing.footerBlock.contactEmail}`}
              className={styled.footerInfo}
            >
              {currentLocal?.landing.footerBlock.contactEmail}
            </a>
            {!isOnlyEn && (
              <a href="tel:79362523389" className={styled.footerInfo}>
                +7 (936) 252 33-89
              </a>
            )}
          </div>
        </div>
        {!isOnlyEn && (
          <>
            <Divider
              sx={{
                height: 1,
                marginTop: '5px',
                marginBottom: '20px',
                background: 'var(--white)',
                zIndex: '1000',
              }}
              orientation="horizontal"
            />
            <Documents />
          </>
        )}
      </footer>
    </Container>
  );
};
