export function Wolfram() {
  return (
    <svg
      width="101"
      height="61"
      viewBox="0 0 101 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101 0V3.38243H100.12C95.7635 3.38243 91.7609 5.81442 89.7073 9.7093L63.2439 59.8996C62.4299 61.4435 60.2137 61.3412 59.5415 59.7286L49.6658 36.0359L37.202 59.1452C36.1714 61.0561 33.4192 60.9145 32.5847 58.9076L12.6067 10.8614C10.7221 6.32911 6.34227 3.38243 1.49028 3.38243H0V0H26.6938V3.51252L22.9391 3.45211C20.8997 3.41929 19.4999 5.52224 20.2933 7.42706L38.1156 50.2161L47.9975 32.003L38.8992 10.0486C37.2581 6.08855 33.4342 3.51252 29.1971 3.51252H27.7205V0H52.2325V3.38243H50.0765C47.6888 3.38243 46.0738 5.85066 47.0026 8.08053L53.0111 22.5062L59.8367 9.9389C61.4378 6.99102 59.334 3.38243 56.0143 3.38243H53.9009V0H74.6912V3.38243H73.3631C69.5139 3.38243 65.9718 5.51278 64.127 8.93739L54.6709 26.4911L64.5527 50.2161L86.0929 10.314C87.7778 7.193 85.5497 3.38243 82.04 3.38243H80.338V0H101Z"
        fill="white"
      />
    </svg>
  );
}
