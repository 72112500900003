import { InputProps } from './Input.types';
import { MainInput } from './styled';

export function ContactInput({
  value,
  onChange,
  label = '',
  placeholder = '',
  isDisabled = false,
  error = '',
  isError = false,
  type = 'text',
  isMultiline = false,
  rowsCount = 0,
  maxRows,
}: InputProps) {
  return (
    <MainInput
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      variant="standard"
      disabled={isDisabled}
      error={isError}
      helperText={error}
      color="primary"
      type={type}
      focused
      multiline={isMultiline}
      rows={rowsCount}
      minRows={maxRows}
    />
  );
}
