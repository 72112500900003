import { CardUsingProps } from './types';
import styled from './CardUsing.module.scss';

export function CardUsing({ title, subtitle, isOne }: CardUsingProps) {
  return (
    <div className={isOne ? styled.root : styled.root2}>
      <div>ISOL</div>

      <div className={styled.title}>{title}</div>
      <div className={styled.subtitle}>{subtitle}</div>
    </div>
  );
}
