import { Button, styled, Box } from '@mui/material';

export const AcceptedButton = styled(Button)({
  fontFamily: 'var(--font-montserrat), sans-serif !important',
  color: 'var(--white) !important',
  border: '1px solid var(--white)',
  borderRadius: 39,
  height: 32,
  width: 150,
  '&:hover': {
    background: 'var(--grey9)',
  },
});

export const LeftSideInfo = styled(Box)({
  width: '460px',
  '@media (max-width: 768px)': {
    width: '300px',
  },

  '@media (max-width: 556px)': {
    width: '300px',
  },
});

export const RightSideInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '27px',
  width: '740px',
  '@media (max-width: 768px)': {
    width: '100%',
  },

  '@media (max-width: 556px)': {
    width: '100%',
  },
});

export const ButtonBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
