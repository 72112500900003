export function Nukomeet() {
  return (
    <svg
      width="76"
      height="73"
      viewBox="0 0 76 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05995 19.0237L11.2938 2.49478C11.8769 1.1625 13.4359 0.583677 14.7334 1.21777L31.5999 9.46078C33.8509 10.62 39.4659 13.0517 43.8318 12.5521C49.4718 11.9066 52.7122 5.96018 54.9632 1.4663C55.6265 0.142014 57.2496 -0.319608 58.5031 0.45346L74.1218 10.0858C75.3813 10.8625 75.7271 12.5549 74.8751 13.7726L69.0599 22.0837C65.6622 27.0212 55.6304 36.716 42.685 35.9947C29.7397 35.2733 17.202 28.0087 8.92006 24.1446L5.29088 22.3899C4.0477 21.7889 3.5024 20.2976 4.05995 19.0237Z"
        fill="white"
      />
      <path
        d="M71.6266 53.1326L64.3927 69.6615C63.8096 70.9937 62.2506 71.5726 60.9532 70.9385L44.0866 62.6955C41.8356 61.5362 36.2206 59.1045 31.8548 59.6041C26.2147 60.2496 22.9744 66.1961 20.7233 70.6899C20.06 72.0142 18.4369 72.4759 17.1834 71.7028L1.56476 62.0705C0.305257 61.2937 -0.0405458 59.6013 0.811433 58.3837L6.62659 50.0725C10.0243 45.135 20.0561 35.4403 33.0015 36.1616C45.9469 36.8829 58.4845 44.1475 66.7665 48.0117L70.3956 49.7663C71.6388 50.3673 72.1841 51.8586 71.6266 53.1326Z"
        fill="white"
      />
    </svg>
  );
}
