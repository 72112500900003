import { Label } from '@/shared/ui/Label';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import styled from './Using.module.scss';
import { CardUsing } from '../CardUsing';

export function Using() {
  const { currentLocal } = useCurrentLocale();
  return (
    <div className={styled.root} id="usage">
      <Label text={currentLocal?.landing.saftyBlock.saftyTitle} />
      <div className={styled.subtitle}>
        {currentLocal?.landing.saftyBlock.saftySubTitle}
      </div>

      <div className={styled.cards}>
        <CardUsing
          title={currentLocal?.landing.saftyBlock.cloudTitle}
          subtitle={currentLocal?.landing.saftyBlock.cloudText}
          isOne
        />
        <CardUsing
          title={currentLocal?.landing.saftyBlock.onprimesTitle}
          subtitle={currentLocal?.landing.saftyBlock.onprimesText}
        />
      </div>
    </div>
  );
}
