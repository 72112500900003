// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import { Label } from '@/shared/ui/Label';
import { ImageSlider } from '@/widgets/Landing/components/InteractiveWithAi/components/ImageSlider';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import styled from './InteractiveWithAi.module.scss';
import image1_ru from '../../../../public/assets/images/tg_image_1.jpeg';
import image2_ru from '../../../../public/assets/images/tg_image_2.jpeg';
import image3_ru from '../../../../public/assets/images/tg_image_3.jpeg';

import image1_en from '../../../../public/assets/images/tg_image_1_eng.jpg';
import image2_en from '../../../../public/assets/images/tg_image_2_eng.jpg';
import image3_en from '../../../../public/assets/images/tg_image_3_eng.jpg';

export function InteractiveWithAi() {
  const { currentLocal, isOnlyEn, isEnVersion } = useCurrentLocale();

  const images: { src: string }[] = isOnlyEn
    ? [image1_en, image2_en, image3_en]
    : isEnVersion
      ? [image1_en, image2_en, image3_en]
      : [image1_ru, image2_ru, image3_ru];

  return (
    <section className={styled.interactiveInfo}>
      <Label text={currentLocal?.landing.unswersBlock.unswerTitle} />

      <div className={styled.container}>
        <div className={styled.desktopCenter}>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.firstText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.secondText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.thirdText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.fourthText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.fiveText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.sixText}
          </p>
          <p className={styled.itemDescription}>
            {' '}
            {currentLocal?.landing.unswersBlock.sevenText}
          </p>
          <p className={styled.itemDescription}>
            {currentLocal?.landing.unswersBlock.eightText}
          </p>
        </div>
        <div className={styled.labels}>
          <ImageSlider images={images} />
        </div>
      </div>
    </section>
  );
}
