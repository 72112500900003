import { motion } from 'framer-motion';
import { GradientProps } from './types';
import styled from './Gradient.module.scss';
import { GRADIENT_VARIANT } from './constants';

export function Gradient({
  width,
  height,
  variant,
  left,
  top,
  right,
  bottom,
  hLeft,
  hTop,
  hRight,
  hBottom,
  hWidth,
  hHeight,
  zIndex,
}: GradientProps) {
  const variants = {
    hover: {
      left: hLeft,
      top: hTop,
      right: hRight,
      bottom: hBottom,
      width: hWidth,
      height: hHeight,
    },
    initial: {
      left,
      top,
      right,
      bottom,
      width,
      height,
      background: GRADIENT_VARIANT[variant],
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
      zIndex,
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      whileHover="hover"
      transition={{ duration: 0.2 }}
      className={styled.root}
    />
  );
}
