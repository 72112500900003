export function Azure() {
  return (
    <svg
      width="76"
      height="67"
      viewBox="0 0 76 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 33.7324L19.0204 -0.00146484H57.3878L76 33.5698L57.3878 66.8972H18.6122L0 33.7324ZM19.0204 48.9329V18.5318C19.0204 15.7484 21.2864 13.4921 24.0816 13.4921H49.3878L57.1429 21.3768V48.9329C57.1429 51.7163 54.8769 53.9726 52.0816 53.9726H24.0816C21.2864 53.9726 19.0204 51.7163 19.0204 48.9329Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 16.2558H23.8367C22.5744 16.2558 21.551 17.2748 21.551 18.5318V48.9329C21.551 50.1899 22.5744 51.2089 23.8367 51.2089H52.1141C53.3637 51.2089 54.3818 50.2145 54.3931 48.9702C54.4841 39.0342 54.3673 22.7587 54.3673 22.7587H48V16.2558ZM46.5306 27.7172V23.8154C46.5306 21.7054 44.8128 19.995 42.6939 19.995C40.5749 19.995 38.8571 21.7054 38.8571 23.8154V27.7172C38.8571 29.8271 40.5749 31.5376 42.6939 31.5376C44.8128 31.5376 46.5306 29.8271 46.5306 27.7172ZM37.551 43.4867V39.585C37.551 37.475 35.8333 35.7645 33.7143 35.7645C31.5953 35.7645 29.8776 37.475 29.8776 39.585V43.4867C29.8776 45.5967 31.5953 47.3072 33.7143 47.3072C35.8333 47.3072 37.551 45.5967 37.551 43.4867ZM33.4694 19.995L30.449 21.8646V23.6529L32.8163 22.271V29.8306H30.449V31.4563H37.0612V29.8306H35.0204V19.995H33.4694ZM42.3673 35.7645L39.3469 37.6341V39.4224L41.7143 38.0405V45.6002H39.3469V47.2259H45.9592V45.6002H43.9184V35.7645H42.3673Z"
        fill="white"
      />
      <path
        d="M44.4082 23.3277V28.2049C44.4082 29.1476 43.6407 29.9119 42.6939 29.9119C41.7471 29.9119 40.9796 29.1476 40.9796 28.2049V23.3277C40.9796 22.3849 41.7471 21.6207 42.6939 21.6207C43.6407 21.6207 44.4082 22.3849 44.4082 23.3277Z"
        fill="white"
      />
      <path
        d="M35.4286 39.0973V43.9744C35.4286 44.9172 34.6611 45.6814 33.7143 45.6814C32.7675 45.6814 32 44.9172 32 43.9744V39.0973C32 38.1545 32.7675 37.3902 33.7143 37.3902C34.6611 37.3902 35.4286 38.1545 35.4286 39.0973Z"
        fill="white"
      />
    </svg>
  );
}
