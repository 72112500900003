export const GRADIENT_VARIANT = {
  pink: 'radial-gradient(ellipse, #C800FF 0%, #E72969 100%)',
  pink2: 'radial-gradient(ellipse, #FF00F5 0%, #4D008A 100%)',
  pink3: 'radial-gradient(ellipse, #E00CA5 0%, rgba(224, 12, 165, 0.69) 100%)',
  orange:
    'radial-gradient(ellipse at 75% 25%, #FFAD55 22%, #FF006B 38%, rgba(173, 0, 255, 0.49) 100%)',
  blue: 'radial-gradient(ellipse, #0500FF 0%, rgba(34, 34, 34, 0.66) 100%)',
  blue2: 'radial-gradient(ellipse, #0500FF 0%, rgba(5, 0, 255, 0.26) 100%)',
  blue3:
    'radial-gradient(ellipse at 25% 75%, rgba(9,4,255, 1) 0%, rgba(28, 21, 71, 0.04) 100%)',
  blue4:
    'radial-gradient(ellipse at 75% 25%, #0500FF 0%, rgba(5, 0, 255, 0) 100%)',
  blue5:
    'radial-gradient(ellipse at 75% 25%, #0500FF 0%, rgba(17, 11, 91, 0) 100%)',
  violet:
    'radial-gradient(ellipse at 50%, #9000C2 0%, rgba(34, 34, 34, 0.49) 100%)',
  violet2:
    'radial-gradient(ellipse at 75% 25%, #6F006A 0%, rgba(34, 34, 34, 0.49) 100%)',
  yellow1:
    'radial-gradient(ellipse at 25% 75%, #F4C38F 13%, #9000C2 44%, rgba(35, 0, 111, 0.74) 100%)',
};
