'use client';

import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './styled';
import { LoaderProps } from './types';

export function Loader({ color, size = 70 }: LoaderProps) {
  return (
    <LoaderContainer>
      <CircularProgress size={size} style={{ color }} />
    </LoaderContainer>
  );
}
