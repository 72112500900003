export function Stories() {
  return (
    <svg
      width="72"
      height="73"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.2857 16.3507L61.1429 17.1567L36 3.99254L4.85714 20.3806L36 36.6343L70.5714 18.6343L72 19.5746V32.0672L36 50.8731L3.28571 33.9478V41.2015L36 58.2612L70.5714 40.1269L72 41.2015V53.8284L36 72.5L0 53.8284V51.8134L1.57143 51.1418L36 69.0075L68.5714 51.9478V44.5597L36 61.6194L0 42.9478V30.1866L1.57143 29.5149L36 47.5149L68.5714 30.3209V22.7985L36 39.7239L0 21.0522V19.306L36 0.5L63.2857 14.8731V16.3507Z"
        fill="white"
      />
    </svg>
  );
}
