import Link from 'next/link';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import styled from './Documents.module.scss';

export const Documents = () => {
  const { currentLocal } = useCurrentLocale();
  return (
    <div className={styled.footerInfoContainer}>
      <ul className={styled.documentsList}>
        © SmartDocs, 2024
        <li>
          <Link
            href="/documents/Инструкция_Пользователя_ПЭВМ_SmartDocs.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styled.documentLink}
          >
            {currentLocal?.landing.footerBlock.documents.instuction}
          </Link>
        </li>
        <li>
          <Link
            href="/documents/Краткое описание ПЭВМ SmartDocs.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styled.documentLink}
          >
            {currentLocal?.landing.footerBlock.documents.smallText}
          </Link>
        </li>
        <li>
          <Link
            href="/documents/Оферта_о_заключении_лицензионного_договора.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styled.documentLink}
          >
            {currentLocal?.landing.footerBlock.documents.oferta}
          </Link>
        </li>
        <li>
          <Link
            href="/documents/Свидетельство_о_регистрации_ПЭВМ_SmartDocs.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styled.documentLink}
          >
            {currentLocal?.landing.footerBlock.documents.svidetelstvo}
          </Link>
        </li>
        <li>
          <Link
            href="/documents/Согласие_на_обработку_персональных_данных.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={styled.documentLink}
          >
            {currentLocal?.landing.footerBlock.documents.soglasie}
          </Link>
        </li>
      </ul>
    </div>
  );
};
