import { Box, styled, Button } from '@mui/material';

export const Container = styled(Box)({
  width: '130px',
  height: '43px',
  borderRadius: '39px',
  border: 'solid 1px var(--white)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.5s background-color',
  '&:hover': {
    background: 'var(--grey9)',
  },
});
export const StartButton = styled(Button)({
  fontSize: '15px',
  fontFamily: 'var(--font-montserrat), sans-serif !important',
  color: 'var(--white) !important',
});
