import { storeAdmin } from '@/shared/store/admin';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import { Container, ContactButton } from './styled';

export const ContactWithUs = () => {
  const { currentLocal } = useCurrentLocale();
  const onOpenContactModal = () => {
    storeAdmin.isOpenModalContactWithUs = true;
  };
  return (
    <Container onClick={onOpenContactModal}>
      <ContactButton variant="text">
        {currentLocal?.landing.footerBlock.contactWithUs}
      </ContactButton>
    </Container>
  );
};
