export function Outlook() {
  return (
    <svg
      width="89"
      height="66"
      viewBox="0 0 89 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7741 0L50.4385 7.86474L58.8215 4.25165L57.9856 13.364L66.8852 15.3826L60.8684 22.262L66.8852 29.1413L57.9856 31.1599L58.8215 40.2723L50.4385 36.6592L45.7741 44.5239L41.1097 36.6592L32.7268 40.2723L33.5626 31.1599L24.6631 29.1413L30.6798 22.262L24.6631 15.3826L33.5626 13.364L32.7268 4.25165L41.1097 7.86474L45.7741 0Z"
        fill="white"
      />
      <path
        d="M89 62.118H87.2564V60.7993H87.2254C86.6778 61.8103 85.8744 62.3158 84.8153 62.3158C84.0352 62.3158 83.423 62.0906 82.9787 61.64C82.5396 61.1894 82.32 60.5933 82.32 59.8515C82.32 58.258 83.1828 57.3294 84.9083 57.0657L87.2641 56.7113C87.2641 55.5079 86.7268 54.9063 85.6523 54.9063C84.7068 54.9063 83.8544 55.2525 83.095 55.9448V54.2717C83.9319 53.7442 84.898 53.4804 85.9932 53.4804C87.9977 53.4804 89 54.5299 89 56.6289V62.118ZM87.2641 57.9723L85.598 58.2196C85.0814 58.291 84.6913 58.4256 84.4279 58.6234C84.1695 58.8157 84.0404 59.1564 84.0404 59.6454C84.0404 60.0026 84.1592 60.2965 84.3969 60.5273C84.6397 60.7526 84.9626 60.8652 85.3655 60.8652C85.9132 60.8652 86.3652 60.6619 86.7217 60.2553C87.0833 59.8432 87.2641 59.3267 87.2641 58.7058V57.9723Z"
        fill="white"
      />
      <path
        d="M81.9933 62.1181H80.1955V57.5025C80.1955 55.8322 79.6711 54.997 78.6223 54.997C78.0954 54.997 77.6511 55.2387 77.2894 55.7223C76.9278 56.2058 76.747 56.824 76.747 57.5767V62.1181H74.9414V49.6232H76.747V55.0794H76.778C77.3772 54.0134 78.2348 53.4805 79.3508 53.4805C81.1125 53.4805 81.9933 54.6261 81.9933 56.9174V62.1181Z"
        fill="white"
      />
      <path
        d="M68.4771 60.8735H68.4461V66H66.6483V53.6782H68.4461V55.1618H68.4771C69.0919 54.0409 69.9908 53.4804 71.1739 53.4804C72.1813 53.4804 72.9666 53.8568 73.5297 54.6096C74.0928 55.3623 74.3744 56.3734 74.3744 57.6426C74.3744 59.0493 74.0567 60.1757 73.4212 61.0218C72.7858 61.868 71.9178 62.2911 70.8174 62.2911C69.8048 62.2911 69.0247 61.8186 68.4771 60.8735ZM68.4306 57.5437V58.5905C68.4306 59.2059 68.614 59.7278 68.9808 60.1564C69.3528 60.585 69.8229 60.7993 70.3912 60.7993C71.0577 60.7993 71.5795 60.5246 71.9566 59.9751C72.3389 59.4201 72.5301 58.6481 72.5301 57.6591C72.5301 56.8294 72.3518 56.181 71.9953 55.714C71.644 55.2415 71.1662 55.0052 70.5617 55.0052C69.9211 55.0052 69.4045 55.247 69.0118 55.7305C68.6244 56.214 68.4306 56.8184 68.4306 57.5437Z"
        fill="white"
      />
      <path
        d="M65.5698 62.1181H63.7642V49.6232H65.5698V62.1181Z"
        fill="white"
      />
      <path
        d="M63.701 62.118H61.6784L60.6787 59.1097H56.3081L55.3471 62.118H53.3323L57.4937 50.299H59.5706L63.701 62.118ZM60.1905 57.5107L58.6484 52.7963C58.6019 52.6425 58.5528 52.3952 58.5012 52.0545H58.4702C58.4237 52.3677 58.372 52.615 58.3152 52.7963L56.7885 57.5107H60.1905Z"
        fill="white"
      />
      <path
        d="M53.4473 62.118H51.6495V57.519C51.6495 56.6344 51.5306 55.9942 51.293 55.5986C51.0605 55.203 50.6653 55.0052 50.1073 55.0052C49.6372 55.0052 49.2368 55.2552 48.9062 55.7552C48.5807 56.2552 48.418 56.8541 48.418 57.552V62.118H46.6124V57.3624C46.6124 55.7909 46.0906 55.0052 45.047 55.0052C44.5614 55.0052 44.161 55.2415 43.8458 55.714C43.5359 56.1865 43.3809 56.7992 43.3809 57.552V62.118H41.583V53.6782H43.3809V55.0134H43.4119C43.9853 53.9914 44.8197 53.4804 45.9149 53.4804C46.4625 53.4804 46.9404 53.6425 47.3486 53.9667C47.7619 54.2854 48.0434 54.7057 48.1932 55.2277C48.7822 54.0629 49.6605 53.4804 50.828 53.4804C52.5742 53.4804 53.4473 54.6261 53.4473 56.9173V62.118Z"
        fill="white"
      />
      <path
        d="M40.6518 62.118H38.9082V60.7993H38.8772C38.3296 61.8103 37.5262 62.3158 36.4671 62.3158C35.687 62.3158 35.0748 62.0906 34.6305 61.64C34.1914 61.1894 33.9718 60.5933 33.9718 59.8515C33.9718 58.258 34.8346 57.3294 36.5601 57.0657L38.9159 56.7113C38.9159 55.5079 38.3787 54.9063 37.3041 54.9063C36.3586 54.9063 35.5062 55.2525 34.7468 55.9448V54.2717C35.5837 53.7442 36.5498 53.4804 37.645 53.4804C39.6496 53.4804 40.6518 54.5299 40.6518 56.6289V62.118ZM38.9159 57.9723L37.2498 58.2196C36.7332 58.291 36.3431 58.4256 36.0797 58.6234C35.8213 58.8157 35.6922 59.1564 35.6922 59.6454C35.6922 60.0026 35.811 60.2965 36.0487 60.5273C36.2915 60.7526 36.6144 60.8652 37.0173 60.8652C37.565 60.8652 38.017 60.6619 38.3735 60.2553C38.7351 59.8432 38.9159 59.3267 38.9159 58.7058V57.9723Z"
        fill="white"
      />
      <path
        d="M34.5596 55.4997C34.3426 55.3184 34.03 55.2277 33.6219 55.2277C33.0898 55.2277 32.6455 55.4832 32.289 55.9942C31.9325 56.5052 31.7543 57.2003 31.7543 58.0794V62.118H29.9564V53.6782H31.7543V55.4173H31.7853C31.9609 54.8239 32.2296 54.3623 32.5912 54.0326C32.958 53.6975 33.3662 53.5299 33.8156 53.5299C34.1411 53.5299 34.3891 53.5821 34.5596 53.6865V55.4997Z"
        fill="white"
      />
      <path
        d="M30.2419 51.1809C29.9991 51.0325 29.7227 50.9584 29.4127 50.9584C28.5396 50.9584 28.1031 51.4831 28.1031 52.5326V53.6782H29.9474V55.1783H28.1108V62.118H26.313V55.1783H24.9568V53.6782H26.313V52.31C26.313 51.4199 26.5868 50.7193 27.1344 50.2083C27.682 49.6918 28.3666 49.4336 29.188 49.4336C29.6323 49.4336 29.9836 49.4858 30.2419 49.5902V51.1809Z"
        fill="white"
      />
      <path
        d="M24.6766 62.1181H22.8709V49.6232H24.6766V62.1181Z"
        fill="white"
      />
      <path
        d="M18.1736 62.3158C16.9492 62.3158 15.9702 61.923 15.2366 61.1372C14.5082 60.346 14.1439 59.2993 14.1439 57.997C14.1439 56.5794 14.5237 55.4722 15.2831 54.6755C16.0477 53.8788 17.0758 53.4804 18.3674 53.4804C19.6073 53.4804 20.5733 53.8678 21.2656 54.6425C21.9579 55.4173 22.304 56.4915 22.304 57.8652C22.304 59.2113 21.9295 60.2911 21.1804 61.1043C20.4364 61.912 19.4342 62.3158 18.1736 62.3158ZM18.2589 55.0052C17.5562 55.0052 17.0009 55.2662 16.5927 55.7882C16.1846 56.3102 15.9805 57.03 15.9805 57.9476C15.9805 58.8322 16.1872 59.53 16.6005 60.041C17.0138 60.5466 17.5666 60.7993 18.2589 60.7993C18.9666 60.7993 19.5091 60.5493 19.8862 60.0493C20.2685 59.5493 20.4597 58.8377 20.4597 57.9146C20.4597 56.986 20.2685 56.269 19.8862 55.7634C19.5091 55.2579 18.9666 55.0052 18.2589 55.0052Z"
        fill="white"
      />
      <path
        d="M14.9873 50.299L11.9418 62.118H9.85724L7.74165 53.8513C7.65382 53.4996 7.59958 53.115 7.57891 52.6974H7.54791C7.52208 53.0875 7.46009 53.4667 7.36193 53.8348L5.23859 62.118H3.10751L0 50.299H2.02259L4.03744 58.9531C4.1201 59.3157 4.17176 59.6949 4.19243 60.0905H4.23117C4.25184 59.8102 4.32416 59.4311 4.44816 58.9531L6.72648 50.299H8.62508L10.7329 59.019C10.8052 59.3157 10.8595 59.6674 10.8957 60.074H10.9267C10.9422 59.7993 11.0016 59.4366 11.1049 58.986L13.081 50.299H14.9873Z"
        fill="white"
      />
    </svg>
  );
}
