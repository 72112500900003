export function QuestionIcon(props: { color: string }) {
  const { color } = props;

  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.3525 94C73.7576 94 94.3525 73.4051 94.3525 48C94.3525 22.5949 73.7576 2 48.3525 2C22.9474 2 2.35254 22.5949 2.35254 48C2.35254 73.4051 22.9474 94 48.3525 94Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9663 34.2001C36.0478 31.1258 38.1824 28.5334 40.9921 26.8821C43.8018 25.2308 47.1052 24.6272 50.3173 25.1782C53.5294 25.7291 56.4429 27.3991 58.5417 29.8923C60.6405 32.3855 61.7892 35.5411 61.7843 38.8001C61.7843 48.0001 47.9843 52.6001 47.9843 52.6001M48.3523 71H48.3983"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
