// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */

import { storeAdmin } from '@/shared/store/admin';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const AlertService = observer(() => {
  const [visibleAlerts, setVisibleAlerts] = useState(
    storeAdmin.alertArray.map((alert) => ({ ...alert, open: true })),
  );
  function handleExited(id: number) {
    setVisibleAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alert.id !== id),
    );
    storeAdmin.alertArray = storeAdmin.alertArray.filter(
      (item) => item.id !== id,
    );
  }
  useEffect(() => {
    setVisibleAlerts(
      storeAdmin.alertArray.map((alert) => ({ ...alert, open: true })),
    );
  }, [storeAdmin.alertArray]);

  function handleClose(id: number, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }
    setVisibleAlerts((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert.id === id ? { ...alert, open: false } : alert,
      ),
    );
  }

  return (
    <>
      {visibleAlerts.map((alert, index) => (
        <Snackbar
          key={alert.id}
          open={alert.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={(event, reason) => handleClose(alert.id, reason)}
          style={{
            marginTop: `${60 * index + 60}px`,
          }}
          TransitionProps={{
            onExited: () => handleExited(alert.id),
          }}
        >
          <Alert
            severity={alert.status}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose(alert.id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {alert.title}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
});

export default AlertService;
