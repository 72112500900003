import { Label } from '@/shared/ui/Label';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import { storeAdmin } from '@/shared/store/admin';
import styled from './TariffPlan.module.scss';
import { Container, StartButton } from './styled';

export function TariffPlan() {
  const onClickTariffPlan = () => {
    storeAdmin.isOpenModalContactWithUs = true;
  };
  const { currentLocal } = useCurrentLocale();
  return (
    <div style={{ marginBottom: '30px' }} id="usage">
      <Label text={currentLocal?.landing.tariffBlock.tarifTitle} />
      <div className={styled.title}>
        {currentLocal?.landing.tariffBlock.onpremiseTitle}
      </div>

      <div className={styled.cards}>
        <div className={styled.root}>
          <div className={styled.containerTitle}>
            {currentLocal?.landing.tariffBlock.cost}
          </div>
          <div className={styled.containerBody}>
            <Container>
              <StartButton variant="text" onClick={onClickTariffPlan}>
                start
              </StartButton>
            </Container>
            <div className={styled.subtitle}>
              {currentLocal?.landing.tariffBlock.startTime}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
