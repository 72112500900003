export function Gitlab() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.91061L41.41 0V72L0 63.8212V7.91061ZM30.3307 50.087L36 22.0553H31.1811L27.9213 43.6838L23.811 22.0553H18.8504L14.1732 43.6838L10.7717 22.0553H5.52756L11.3386 50.087H16.8661L21.1181 29.17L25.2283 50.087H30.3307Z"
        fill="white"
      />
      <path
        d="M63.555 16.4916V22.1229H55.436H52.4972H44.2152V27.486H52.4972H55.436H63.555V33.1173H55.436H52.4972H44.2152V38.4804H52.4972H55.436H63.555V44.1117H55.436H52.4972H44.2152V49.4749H52.4972H55.436H63.555V55.1061H55.436H52.4972H44.2152V63.6871H69.1654C70.7309 63.6871 72 62.413 72 60.8413V10.7565C72 9.18474 70.7309 7.91061 69.1654 7.91061H44.2152V16.4916H52.4972H55.436H63.555Z"
        fill="white"
      />
    </svg>
  );
}
