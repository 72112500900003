export function MlFLow() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3368 36C26.3368 40.2679 24.0464 43.7277 21.2211 43.7277C18.3957 43.7277 16.1053 40.2679 16.1053 36C16.1053 31.7321 18.3957 28.2723 21.2211 28.2723C24.0464 28.2723 26.3368 31.7321 26.3368 36Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3368 36C26.3368 40.2679 24.0464 43.7277 21.2211 43.7277C18.3957 43.7277 16.1053 40.2679 16.1053 36C16.1053 31.7321 18.3957 28.2723 21.2211 28.2723C24.0464 28.2723 26.3368 31.7321 26.3368 36Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.10471L42.0632 0V72L0 63.5183V8.10471ZM21.2211 48.6283C26.5579 48.6283 30.8842 42.89 30.8842 35.8115C30.8842 28.733 26.5579 22.9948 21.2211 22.9948C15.8842 22.9948 11.5579 28.733 11.5579 35.8115C11.5579 42.89 15.8842 48.6283 21.2211 48.6283Z"
        fill="white"
      />
      <path
        d="M44.9053 53.7173H67.8316C70.1337 53.7173 72 51.8608 72 49.5707V23.9372L50.4161 37.94C50.291 38.0211 50.1297 38.0216 50.0041 37.9413L44.9053 34.6806V53.7173Z"
        fill="white"
      />
      <path
        d="M69.1579 15.267H44.9053V29.4031L50 32.6612C50.1277 32.7428 50.292 32.7408 50.4176 32.6561L72 18.0942C72 16.5328 70.7275 15.267 69.1579 15.267Z"
        fill="white"
      />
    </svg>
  );
}
