import { MainAlertProps } from './MainAlert.types';
import { MessageAlert } from './styled';

export function MainAlert({ children, type }: MainAlertProps) {
  return (
    <MessageAlert severity={type} variant="filled">
      {children}
    </MessageAlert>
  );
}
