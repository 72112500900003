import { ArrowDownIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/ArrowDownIcon/ArrowDownIcon';
import { BookIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/BookIcon/BookIcon';
import { NeuroIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/NeuroIcon/NeuroIcon';
import { UserIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/UserIcon/UserIcon';
import { SettingsIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/SettingsIcon/SettingsIcon';
import { QuestionIcon } from '@/public/assets/icons/LandingIcons/InteractiveIcons/QuestionIcon/QuestionIcon';
import tg from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Telegram.svg';
import figma from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Figma.svg';
import ai21 from '@/public/assets/icons/LandingIcons/IntegrationsIcons/AI21.svg';
import arxiv from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Arxiv.svg';
import databricks from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Databricks.svg';
import trello from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Trello.svg';
import word from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Word.svg';
import outlook from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Outlook.svg';
import cassandra from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Cassandra.svg';
import miflow from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Miflow.svg';
import elasticsearch from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Elasticsearch.svg';
import exel from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Exel.svg';
import gitBook from '@/public/assets/icons/LandingIcons/IntegrationsIcons/GitBook.svg';
import serper from '@/public/assets/icons/LandingIcons/IntegrationsIcons/serper.svg';
import git from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Git.svg';
import blobStorage from '@/public/assets/icons/LandingIcons/IntegrationsIcons/BlobStorage.svg';
import wolframAlpha from '@/public/assets/icons/LandingIcons/IntegrationsIcons/WolframAlpha.svg';
import wiki from '@/public/assets/icons/LandingIcons/IntegrationsIcons/Wikipedia.svg';

/**
 * TODO!!! ПЕРЕДЕЛАТЬ ЭТОТ ФАЙЛ ПОЛНОСТЬЮ!!! УБРАТЬ ЭТОТ КОСТЫЛЬ!!!
 * ПОЧЕМУ ОНО ВООБЩЕ НАХОДИТЬСЯ В ГЛОБАЛЬНЫХ КОНСТАНТАХ, ЕСЛИ ИЗ НАЗВАНИЯ
 * ПОНЯТНО ЧТО ИСПОЛЬЗУЕТСЯ ТОЛЬКО И ТОЛЬКО НА СТРАНИЦЕ ЛЕНДИНГА???
 */

export interface InfoItemType {
  title: string;
  description: string;
  icon: JSX.Element;
}
interface FirstInfoBlockInterface {
  secondTitle: string;
  secondSubTitle: string;
  thirdTitle: string;
  thirdSubTitle: string;
  fourthTitle: string;
  fourthSubTitle: string;
}
interface SecondBlockInterface {
  firstTitle: string;
  firstText: string;
  secondTitle: string;
  secondText: string;
  thirdTitle: string;
  thirdText: string;
}
export function firstInfoItems({
  secondTitle,
  secondSubTitle,
  thirdTitle,
  thirdSubTitle,
  fourthTitle,
  fourthSubTitle,
}: FirstInfoBlockInterface): InfoItemType[] {
  return [
    {
      title: secondTitle,
      description: secondSubTitle,
      icon: <ArrowDownIcon color="var(--white)" />,
    },
    {
      title: thirdTitle,
      description: thirdSubTitle,
      icon: <NeuroIcon color="var(--white)" />,
    },
    {
      title: fourthTitle,
      description: fourthSubTitle,
      icon: <QuestionIcon color="var(--white)" />,
    },
  ];
}

export function secondInfoItems({
  firstTitle,
  firstText,
  secondTitle,
  secondText,
  thirdTitle,
  thirdText,
}: SecondBlockInterface): InfoItemType[] {
  return [
    {
      title: firstTitle,
      description: firstText,
      icon: <BookIcon color="var(--white)" />,
    },
    {
      title: secondTitle,
      description: secondText,
      icon: <UserIcon color="var(--white)" />,
    },
    {
      title: thirdTitle,
      description: thirdText,
      icon: <SettingsIcon color="var(--white)" />,
    },
  ];
}

export const logoArray = [
  {
    icon: tg,
    alt: 'tg',
  },
  {
    icon: figma,
    alt: 'figma',
  },
  {
    icon: ai21,
    alt: 'ai21',
  },
  {
    icon: arxiv,
    alt: 'arxiv',
  },
  {
    icon: blobStorage,
    alt: 'blobStorage',
  },
  {
    icon: databricks,
    alt: 'databricks',
  },
  {
    icon: elasticsearch,
    alt: 'elasticsearch',
  },
  {
    icon: git,
    alt: 'git',
  },
  {
    icon: gitBook,
    alt: 'gitBook',
  },
  {
    icon: serper,
    alt: 'serper',
  },
  {
    icon: cassandra,
    alt: 'cassandra',
  },
  {
    icon: trello,
    alt: 'trello',
  },
  {
    icon: word,
    alt: 'word',
  },
  {
    icon: exel,
    alt: 'exel',
  },
  {
    icon: outlook,
    alt: 'outlook',
  },
  {
    icon: wolframAlpha,
    alt: 'wolframAlpha',
  },
  {
    icon: wiki,
    alt: 'wiki',
  },
  {
    icon: miflow,
    alt: 'miflow',
  },
];

export const logoSystem = [{}];
