export function Ai21labs() {
  return (
    <svg
      width="85"
      height="68"
      viewBox="0 0 85 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.4023 50.5355L77.3401 50.7255C77.2667 50.2189 77.0675 49.7703 76.7424 49.3798C76.4174 48.9787 75.9927 48.6674 75.4684 48.4457C74.9546 48.2135 74.3569 48.0974 73.6753 48.0974C72.7841 48.0974 72.0238 48.2769 71.3947 48.6357C70.776 48.9946 70.4719 49.4801 70.4824 50.0922C70.4719 50.5672 70.6607 50.9788 71.0487 51.3271C71.4471 51.6754 72.1549 51.9551 73.172 52.1662L77.8277 53.0527C80.2395 53.5171 82.0325 54.2876 83.2069 55.3642C84.3918 56.4407 84.9895 57.8656 85 59.6387C84.9895 61.3063 84.4967 62.7575 83.5215 63.9924C82.5568 65.2273 81.2356 66.1877 79.5579 66.8738C77.8801 67.5492 75.9613 67.887 73.8012 67.887C70.3513 67.887 67.6303 67.1746 65.638 65.7497C63.6562 64.3143 62.5237 62.3934 62.2406 59.987L69.8375 59.797C70.0053 60.6836 70.4405 61.3591 71.143 61.8235C71.8456 62.2879 72.7421 62.5201 73.8326 62.5201C74.8183 62.5201 75.6205 62.3354 76.2391 61.966C76.8578 61.5966 77.1724 61.1058 77.1829 60.4936C77.1724 59.9448 76.9312 59.5068 76.4593 59.1796C75.9875 58.8418 75.2482 58.578 74.2416 58.388L70.0263 57.5806C67.6041 57.1373 65.8005 56.3193 64.6156 55.1267C63.4307 53.9235 62.8435 52.3931 62.854 50.5355C62.8435 48.9101 63.2734 47.5222 64.1438 46.3718C65.0141 45.2108 66.2514 44.3242 67.8557 43.7121C69.4601 43.0999 71.3527 42.7938 73.5338 42.7938C76.8054 42.7938 79.3849 43.4852 81.2723 44.8678C83.1597 46.2399 84.2031 48.1291 84.4023 50.5355Z"
        fill="white"
      />
      <path d="M10.8368 35.0048V67.428H3.14544V35.0048H10.8368Z" fill="white" />
      <path
        d="M40.6986 0.0745551V32.4977H32.9129V0.0745551H40.6986Z"
        fill="white"
      />
      <path
        d="M44.3522 21.4697C42.6105 24.4981 42.4521 29.8639 42.6104 32.7859H64.4623V26.2513H51.1612C51.1612 24.1794 53.5634 22.7004 60.1869 17.9634C66.2041 13.6601 65.4124 7.28469 63.1955 3.77827C60.9787 0.271841 53.0613 -1.16261 48.1526 1.06876C44.2256 2.85384 42.8216 7.97532 42.6104 10.3129H50.2111C50.5278 7.28464 52.428 5.41202 54.6448 5.69094C56.8617 5.96986 57.97 7.60328 57.8117 9.67539C57.6535 11.7475 54.3281 13.8193 51.1612 15.7319C47.9943 17.6445 46.0389 18.5371 44.3522 21.4697Z"
        fill="white"
      />
      <path
        d="M67.471 7.01285H66.2042V11.635H72.6964V32.6735H80.4554V0.637532H73.8048C73.8048 4.15852 70.9691 7.01285 67.471 7.01285Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 32.4977H8.36767L10.4867 25.6901H21.6111L23.7346 32.4977H32.1023L21.2337 0.0745551H10.8685L0 32.4977ZM19.7543 19.7374L16.1691 8.24368H15.9174L12.3397 19.7374H7.25093V25.6901H10.4867L12.3397 19.7374H19.7543Z"
        fill="white"
      />
      <path
        d="M7.25093 19.7374V25.6901H10.4867L12.3397 19.7374H7.25093Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.262 64.3978C27.3565 66.5525 24.656 67.8971 21.6613 67.8971C15.8895 67.8971 11.2104 62.9021 11.2104 56.7403C11.2104 50.5786 15.8895 45.5835 21.6613 45.5835C24.656 45.5835 27.3565 46.9282 29.262 49.0828V56.7403C29.262 53.7475 26.8516 51.3213 23.8782 51.3213C20.9048 51.3213 18.4944 53.7475 18.4944 56.7403C18.4944 59.7332 20.9048 62.1593 23.8782 62.1593C26.8516 62.1593 29.262 59.7332 29.262 56.7403V64.3978ZM29.262 64.3978C31.029 62.3997 32.1122 59.7051 32.1122 56.7403C32.1122 53.7756 31.029 51.0809 29.262 49.0828V45.5835H35.9125V67.8971H29.262V64.3978Z"
        fill="white"
      />
      <path
        d="M29.262 49.0828V56.7403V64.3978C31.029 62.3997 32.1122 59.7051 32.1122 56.7403C32.1122 53.7756 31.029 51.0809 29.262 49.0828Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8298 36.9768H37.1793V67.8971H43.8298V64.3978C45.7353 66.5525 48.4358 67.8971 51.4305 67.8971C57.2023 67.8971 61.8814 62.9021 61.8814 56.7403C61.8814 50.5786 57.2023 45.5835 51.4305 45.5835C48.4358 45.5835 45.7353 46.9282 43.8298 49.0828V36.9768ZM43.8298 56.7403V64.3978C42.0628 62.3997 40.9796 59.7051 40.9796 56.7403C40.9796 53.7756 42.0628 51.0809 43.8298 49.0828V56.7403ZM43.8298 56.7403C43.8298 53.7475 46.2403 51.3213 49.2136 51.3213C52.187 51.3213 54.5974 53.7475 54.5974 56.7403C54.5974 59.7332 52.187 62.1593 49.2136 62.1593C46.2403 62.1593 43.8298 59.7332 43.8298 56.7403Z"
        fill="white"
      />
      <path
        d="M43.8298 64.3978V56.7403V49.0828C42.0628 51.0809 40.9796 53.7756 40.9796 56.7403C40.9796 59.7051 42.0628 62.3997 43.8298 64.3978Z"
        fill="white"
      />
    </svg>
  );
}
