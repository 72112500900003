export function Defi() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2152 16.4916H52.4972V22.1229H44.2152V27.486H52.4972V33.1173H44.2152V38.4804H52.4972V44.1117H44.2152V49.4749H52.4972V55.1061H44.2152V63.6872H69.3284C70.8039 63.6872 72 62.4866 72 61.0056V10.5922C72 9.11119 70.8039 7.91061 69.3284 7.91061H44.2152V16.4916ZM66.3896 22.1229V16.4916H55.436V22.1229H66.3896ZM66.3896 33.1173V27.486H55.436V33.1173H66.3896ZM66.3896 44.1117V38.4804H55.436V44.1117H66.3896ZM66.3896 55.1061V49.4749H55.436V55.1061H66.3896Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.91061L41.41 0V72L0 63.8212V7.91061ZM16.6976 36.067L7.88126 49.8771H15.2282L20.705 39.0168L26.1818 49.8771H33.5288L25.1132 36.067L33.2616 21.8547H26.1818L21.1058 32.581L16.1633 21.8547H8.54916L16.6976 36.067Z"
        fill="white"
      />
    </svg>
  );
}
