export function UserIcon(props: { color: string }) {
  const { color } = props;

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.6753 68.0004V63.667C66.6753 61.3685 65.7534 59.1641 64.1125 57.5388C62.4715 55.9135 60.2459 55.0004 57.9253 55.0004H40.4253C38.1046 55.0004 35.8791 55.9135 34.2381 57.5388C32.5972 59.1641 31.6753 61.3685 31.6753 63.667V68.0004M57.6751 37.5C57.6751 42.1944 53.8695 46 49.175 46C44.4806 46 40.675 42.1944 40.675 37.5C40.675 32.8056 44.4806 29 49.175 29C53.8695 29 57.6751 32.8056 57.6751 37.5Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6753 94C74.0804 94 94.6753 73.4051 94.6753 48C94.6753 22.5949 74.0804 2 48.6753 2C23.2702 2 2.67529 22.5949 2.67529 48C2.67529 73.4051 23.2702 94 48.6753 94Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
